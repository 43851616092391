
import './App.css';
import React from 'react';
import Slider from '@mui/material/Slider';
import { Box } from '@mui/material';
import { TextField, styled } from '@mui/material';
import copy from 'copy-to-clipboard';

var crypto = window.crypto;

const SonderzeichenField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: "15px",
    marginBottom: "15px",
    height: "70px",
    '& fieldset': {
      borderColor: 'aliceblue'
    },
    '&:hover fieldset': {
      borderColor: 'aliceblue'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'aliceblue'
    }
  }
});

class App extends React.Component {
  state = {
    pswd: -1,
    ButtonText: "generate new",
    ButtonTextLittle: "a",
    ButtonTextBig: "A",
    ButtonTextNums: "0-9",
    bigLettersActive: "bigOFF",
    smallLettersActive: "smallOFF",
    numsActive: "numOFF",
    digits: 60,
    copied: false,
    textfield: "",
    buttonDisabled: true,
  };

  newPswd = () => {
    var alphabet = ""
    if (this.state.textfield.length > 0) {
      var t = 26 / this.state.textfield.length;
      for (var j = 0; j < t; j++) {
        alphabet += this.state.textfield;
      }
    }
    if (this.state.bigLettersActive === "bigON") {
      alphabet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    }
    if (this.state.smallLettersActive === "smallON") {
      alphabet += "abcdefghijklmnopqrstuvwxyz";
    }
    if (this.state.numsActive === "numON") {
      alphabet += "012345678901234567890123456789";
    }
    if (alphabet.length === 0) {
      this.setState({ pswd: -1 });
      return;
    }

    if (alphabet.length > 255) {
      this.setState({ pswd: "too many special characters!" })
      return;
    }


    var temp = "";
    for (var i = 0; i < this.state.digits; i) {
      var arraytemp = new Uint8Array(1);
      var tempCryptoNum = crypto.getRandomValues(arraytemp);
      if (tempCryptoNum < alphabet.length) {
        temp += alphabet[tempCryptoNum];
        i++;
      }


    }
    this.setState({ pswd: temp });
  }

  copy = async () => {

    this.setState({ copied: true });
    await new Promise(resolve => setTimeout(resolve, 1000));
    this.setState({ copied: false });

  }

  _handleTextFieldChange = (e) => {
    this.setState({
      textfield: e.target.value
    });
  }

  textCeckButton = async () => {
    if (this.state.bigLettersActive === "bigOFF" && this.state.smallLettersActive === "smallOFF" && this.state.numsActive === "numOFF") {
      this.setState({ ButtonText: "x" });
    } else {
      this.setState({ ButtonText: "✓" });
    }
    await new Promise(resolve => setTimeout(resolve, 1500));
    this.setState({ ButtonText: "generate new" });
  }

  activateBigLetters = async () => {
    if (this.state.bigLettersActive === "bigOFF") {
      this.setState({ bigLettersActive: "bigON" }); this.setState({ ButtonTextBig: "✓" });
      this.setState({ buttonDisabled: false });
      await new Promise(resolve => setTimeout(resolve, 1000));
      this.setState({ ButtonTextBig: "A" });
    } else {
      this.setState({ bigLettersActive: "bigOFF" });
      if (this.state.smallLettersActive === "smallOFF" && this.state.numsActive === "numOFF") { this.setState({ buttonDisabled: true });
      this.setState({ pswd: -1 }); }
    }
  }

  activateSmallLetters = async () => {
    if (this.state.smallLettersActive === "smallOFF") {
      this.setState({ smallLettersActive: "smallON" })
      this.setState({ ButtonTextLittle: "✓" });
      this.setState({ buttonDisabled: false });
      await new Promise(resolve => setTimeout(resolve, 1000));
      this.setState({ ButtonTextLittle: "a" });

    } else {
      this.setState({ smallLettersActive: "smallOFF" });
      if (this.state.bigLettersActive === "bigOFF"  && this.state.numsActive === "numOFF") { this.setState({ buttonDisabled: true });
      this.setState({ pswd: -1 }); }
    }

  }
  activateNums = async () => {
    if (this.state.numsActive === "numOFF") {
      this.setState({ numsActive: "numON" });
      this.setState({ ButtonTextNums: "✓" });
      this.setState({ buttonDisabled: false });
      await new Promise(resolve => setTimeout(resolve, 1000));
      this.setState({ ButtonTextNums: "0-9" });
    }
    else {
      this.setState({ numsActive: "numOFF" });
      if (this.state.bigLettersActive === "bigOFF" && this.state.smallLettersActive === "smallOFF") { this.setState({ buttonDisabled: true });
      this.setState({ pswd: -1 }); }
    }

  }


  render() {
    return (
      <html>
        <head>
          <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover"></meta>
        </head>
        <div className="App">
          <header className="App-header">
            <header className='test'>
              <h1 className="Header">
                Passwort generator
              </h1>
            </header>
            <Box sx={{ flexWrap: 'auto' }} maxWidth="75%">
              <button className='button-generator-field' onClick={() => { this.state.pswd === "" ? void 0 : copy(this.state.pswd); this.copy() }}>
                <div className='generator-field'>
                  {this.state.pswd === -1 || this.state.buttonDisabled ? "..." : (this.state.copied === true ? "copied!" : this.state.pswd)}
                </div>
              </button>
            </Box>
            <Box className='buttonrow' marginBottom={5}>
              <button className={this.state.bigLettersActive} onClick={() => { this.activateBigLetters() }}>{this.state.ButtonTextBig}</button>
              <button className={this.state.smallLettersActive} onClick={() => { this.activateSmallLetters() }}>{this.state.ButtonTextLittle}</button>
              <button className={this.state.numsActive} onClick={() => { this.activateNums() }}>{this.state.ButtonTextNums}</button>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }} marginBottom={3}>
              <SonderzeichenField placeholder='opt.: %$&' sx={{ input: { color: 'aliceblue' } }} inputProps={{ style: { fontSize: 23 } }} value={this.state.textfield} onChange={this._handleTextFieldChange}></SonderzeichenField>
            </Box>
            <Box width={70} marginBottom={1} display="flex" justifyContent="center" alignItems="center" height={70} sx={{ backgroundColor: 'white', borderRadius: '15px', color: 'black' }}>{this.state.digits}</Box>
            <Box marginBottom={3} width={'90%'}>
              <Slider
                onChange={(e, val) => this.setState({ digits: val })}
                min={10}
                max={120}
                className='slider'
                defaultValue={60}
                valueLabelDisplay="auto"
                sx={{
                  color: 'aliceblue',
                  width: '100%'
                }}
              />
            </Box>
            {/* ein/auskommentieren von disabeled um button auszugrauen wenn nichts ausgewählt ist */}
            <button className='button' disabled={this.state.buttonDisabled} onClick={() => { this.newPswd(); this.textCeckButton(); }}>{this.state.ButtonText}</button>
          </header>
        </div>
      </html>
    )
  }
}
export default (App);